if ($('.artist-js').length > 0) {

  let artist_id = $( ".aritst-hidden-field-js" )[0].value

  const variousId_1 = 2555 // ID for production value only
  const variousId_2 = 2556 // ID for production value only
  const variousId_3 = 7209 // ID for production value only

  if (artist_id != variousId_1 && artist_id != variousId_2 && artist_id != variousId_3) {
    addPrompt();
  }

  $(".aritst-hidden-field-js").change(function() {
    $('.master-list-js').empty()
    artist_id = $( ".aritst-hidden-field-js" )[0].value
    if (artist_id != variousId_1 && artist_id != variousId_2 && artist_id != variousId_3) {
      addPrompt();
    } else {
      $('.master-prompt-cell-js').addClass("hide")
      $('.master-list-js').removeClass("c-input__promptContainer")
    }
  });

  function addPrompt() {
    if (Number(artist_id) > 0) {
      $('.master-list-js')
        .append("<div class='cell'> <p>To keep our library free of duplicates please check if an entry for your record already exists. If you see it below, please click it and you will be taken to a page to see if we have the exact version of your record. If we don’t, you can add the version of your record from there. Thanks!</p></div>")
      $.get( `/masters_for_contribution?artist_id=${artist_id}`, function( alldata ) {
        alldata.forEach(function(data) {
          $('.master-list-js')
            .append(`<div class='cell medium-6'><a rel="nofollow" href="/contribution/masters/${data.id}/versions">${data.title}</a></div>`)
        })
      }, "json" )
      $('.master-prompt-cell-js').removeClass("hide")
      $('.master-list-js').addClass("c-input__promptContainer")
    } else {
      $('.master-prompt-cell-js').addClass("hide")
      $('.master-list-js').removeClass("c-input__promptContainer")
    }
  }
}
