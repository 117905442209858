const conditionSelectors = document.querySelectorAll('.js-conditionSelector');

function conditionToolTip(selector) {
  const toolTipContainer = document.querySelector('.c-condition__toolTip');

  toolTipContainer.classList.remove('hide');
  toolTipContainer.append('.c-condition__toolTipArrow');

  let parent = selector.parentNode;
  let title = parent.querySelector(".js-toolTip__title").innerText;
  let description = parent.querySelector(".js-toolTip__description").innerText;

  let toolTipContent = `
    <h6>${title}</h6>
    <p>${description}</p>
  `;

  toolTipContainer.innerHTML = toolTipContent;
}

conditionSelectors.forEach(function(item) {
  item.addEventListener("change", function() {
    conditionToolTip(item);
  });
});
