import $ from 'jquery';

const setupsaleTypeTriggers = (() => {

  const buyItNowStartTriggers = document.querySelectorAll('.js-buyItNowStartTrigger');

  if (!buyItNowStartTriggers) return;

  buyItNowStartTriggers.forEach(trigger => {
    trigger.addEventListener('click', (event) => {
      $('#js-buyItNowStartModal').foundation('open');
      const id = event.currentTarget.dataset.formAction
      const currency = event.currentTarget.dataset.currency
      document.querySelectorAll(".js-inputCurrency").forEach(function (element) {
        element.innerHTML = currency;
      })
      document.querySelector(".start-buy-it-now").action = `/listings/${id}/pricing`;
    })
  });

})();


// Form handling
const buyItNowFormHandling = (() => {

  const buyItNowForm = document.querySelector(".start-buy-it-now");
  const buyItNowActions = document.getElementById('buyItNowActions');

  if( !buyItNowForm ) return;

  let message = "";

  buyItNowForm.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    buyItNowActions.innerHTML = `<span class="c-formMessage">${message}</span>`;

    setTimeout(() => {
      location.reload();
    }, 300);
  });

  buyItNowForm.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.price_cents;
    buyItNowActions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });

})();
