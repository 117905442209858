import $ from 'jquery';

$(document).ready(function() {
  $('input[type="radio"]').click(function() {
    if ($(this).val() == "No Discount") {
      $('#no-discount').removeClass('hide');
      $('#highest-price').addClass('hide');
      $('#free-shipping').addClass('hide');
    } else if ($(this).val() == "Highest Price With Surcharge"){
      $('#highest-price').removeClass('hide');
      $('#no-discount').addClass('hide');
      $('#free-shipping').addClass('hide');
    }
    else if ($(this).val() == "Free Shipping"){
      $('#free-shipping').removeClass('hide');
      $('#no-discount').addClass('hide');
      $('#highest-price').addClass('hide');
    }
  });
});
