// Entry point for the build script in your package.json
// DO NOT LOAD CSS IN THIS, or any of the js files. Doing so will rebuild assets/build/application.css
// and the rest of the site's css will be replaced

import Rails from "@rails/ujs"

// Cannot import jquery-ui through node with esbuild.
// See https://www.youtube.com/watch?v=ql-Ng6OeI-M for reference
import "./src/jquery";
import "./src/jquery-ui-full";

import "./src/foundation.js"

import "./src/select2.js"

import slick from 'slick-slider';

import "lazysizes";

// Used in admin
import * as bootstrap from "bootstrap"

import "@fortawesome/fontawesome-free/js/all"

// Custom files

import "./src/modules/add-copies-modal"
import "./src/modules/adjust-postage-modal"
import "./src/modules/answer-question-buttons"
import "./src/modules/artist-autocomplete"
import "./src/modules/auction-countdown"
import "./src/modules/auction-start-modal"
import "./src/modules/audio-listing-payment-modal"
import "./src/modules/basic-details-field-hide"
import "./src/modules/bid-messages"
import "./src/modules/bulk-listings-form"
import "./src/modules/bulk-shipping"
import "./src/modules/buy-it-now-start-modal"
import "./src/modules/carousel-modal"
import "./src/modules/condition-selector"
import "./src/modules/contribution-prompt"
import "./src/modules/cookie-policy"
import "./src/modules/country-select-checked"
import "./src/modules/currency-input"
import "./src/modules/devise-password-confirmation"
import "./src/modules/disc-condition"
import "./src/modules/facebook-share-button"
import "./src/modules/featured-listing"
import "./src/modules/flash-messages"
import "./src/modules/free-postage"
import "./src/modules/hide-errors-contribution"
import "./src/modules/home-page-sliders"
import "./src/modules/listing-carousel"
import "./src/modules/listing-custom-property-change"
import "./src/modules/listing-file-payment"
import "./src/modules/listing-index-modal"
import "./src/modules/listing-preorder-toggle"
import "./src/modules/listing-title-descriptor-validation"
import "./src/modules/merge-master-ids"
import "./src/modules/mobile-nav"
import "./src/modules/move-master"
import "./src/modules/multi-photo-upload"
import "./src/modules/multiple-artists-select"
import "./src/modules/my-elvinyl-menu"
import "./src/modules/navbar-search"
import "./src/modules/new-shop-modal"
import "./src/modules/no-enter-submit-contribution"
import "./src/modules/offer-modal"
import "./src/modules/order-review"
import "./src/modules/password-validation"
import "./src/modules/paypal-cart-checkout"
import "./src/modules/paypal-checkout"
import "./src/modules/photo-upload"
import "./src/modules/prevent-context-menu"
import "./src/modules/prodigi-preview"
import "./src/modules/question-mark-hints"
import "./src/modules/record-spinner"
import "./src/modules/resolve-before-feedback-prompt"
import "./src/modules/sales-history-links"
import "./src/modules/search-bar-dropdown"
import "./src/modules/search-bar-results"
import "./src/modules/search-filters"
import "./src/modules/select2"
import "./src/modules/sub-genre-select"
import "./src/modules/svg-sprite"
import "./src/modules/tax-info-box"
import "./src/modules/time-converter"
import "./src/modules/twitter-share-button"
import "./src/modules/update-78"
import "./src/modules/update-cart-price"
import "./src/modules/user-details-modal"
import "./src/modules/user-rating-modal"
import "./src/modules/username-validation"
import "./src/modules/wantlist"
import "./src/modules/whatsapp-share-button"
import "./src/modules/zoom-on-hover"
import "./src/modules/admin"

Rails.start()
