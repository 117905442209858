import $ from "jquery";
import Sortable from "sortablejs";
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

const submitForm = document.querySelector(
  "#c-multiImageUploader__submitForm"
);

const csrfToken = $('meta[name="csrf-token"]').attr("content");

if (submitForm) {
  /** *************
   * Dropzone for multi-file uploading
   * **************/
  const attachmentUploader = new Dropzone(submitForm, {
    clickable: ".c-multiImageUploader__textWrapper",
    acceptedFiles: ".jpg,.jpeg,.png",
    maxFilesize: 10,
    dictDefaultMessage: "",
    timeout: 0,
    parallelUploads: 1,
    maxFiles: 16,
    thumbnailWidth: 325,
    thumbnailHeight: 325,
    thumbnailMethod: "contain",
    previewsContainer: ".dropzone-previews",
    previewTemplate: document.querySelector("#dz-template-container")
      .innerHTML,
    /* Re-implementing the size validation previously on the backend - uploaded images must be at least
      340px x 340px */
    init: function () {
      this.on("thumbnail", function (file) {
        if (file.height < 340 || file.width < 340) {
          file.reject("Image must be at least 340px x 340px");
        } else {
          file.accept();
        }
      });
    },
    accept: function (file, done) {
      file.accept = done;
      file.reject = (msg) => {
        done(msg);
      };
    },
  });

  /* If uploading multiple files, this will be called multiple times. */
  attachmentUploader.on("success", function (file, request) {
    const resp = $.parseXML(request);
    const remoteUrl = $(resp).find("Location").text();

    $.post($(submitForm).data("saveUrl"), {
      authenticity_token: csrfToken,
      remote_url: remoteUrl,
    })
      .fail(function (xhr, _error, _message) {
        // If saving the uploaded file as an attachment fails,
        // we can tell Dropzone to treat it like an upload error
        attachmentUploader.emit("error", file, xhr.responseJSON[0], xhr);
        // And also display a nicer error message for the user
      })
      .done(function ({ id }, _status, _xhr) {
        // So that sortable/delete work with newly updated files, we need to update the data
        // attributes on the generated HTML
        file.previewElement.setAttribute("data-id", id);
        file.previewElement.setAttribute(
          "data-delete-url",
          file.previewElement
            .getAttribute("data-delete-url")
            .replace(":id:", id)
        );
      });

    // The animation for the tick icon is 3s - remove the class that causes the animation afterwards
    // If we don't remove it, the animation will replay when dragging and dropping the attachment (because it
    // gets re-inserted into the DOM)
    setTimeout(() => {
      file.previewElement.classList.remove("dz-success");
    }, 3000);
  });

  attachmentUploader.on("sending", function (file, xhr, formData) {
    // Stopped working after rails 6 upgrade. Found a fix here
    // https://github.com/dwilkie/carrierwave_direct/issues/199#issuecomment-214863968
    formData.append('utf8', '')
    formData.append("Content-Type", file.type);
  });

  // Remove file automatically after error. Needed for completion of sorting
  attachmentUploader.on('error', function(file) {
    setTimeout(() => {
      file.previewElement.remove();
    }, 5000);
  })

  /** *************
   * Re-ordering uploaded attachmnents
   * **************/
  var el = document.querySelector(".sortable");
  var notFirefox = !(navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
  if (el) {
    Sortable.create(el, {
      animation: 0,
      // Needs to be set to true for everything which isn't firefox.
      // Breaks the drag feature both ways when this is not dynamically set
      forceFallback: notFirefox,
      onSort: function () {
        sendAjaxRequest(el)
      }
    });
  }

  const sendAjaxRequest = (el) => {
    const button = document.querySelector('.js-multiImageUploader__progressButton')
    // Creat array of attachment ids in desired order
    const attachments = el.querySelectorAll("[data-id]");
    let attachmentIds = [];
    attachments.forEach((attachment) => {
      attachmentIds.push(attachment.dataset.id);
    });
    // Checks initial upload has completed and that the array has a full set of ids.
    // If upload has not completed this array will be incomplete and won't order properly.
    // If this is the case, the sendAjaxRequest function is recalled until the array is full.
    if (attachmentIds.includes('')) {
      // Stop progression until ordering can be completed
      button.style.pointerEvents = "none"
      button.value = 'Processing...'
      button.innerText = 'Processing...'
      setTimeout(() => {
        sendAjaxRequest(el);
      }, 2000);
    } else {
      $.ajax({
        type: "post",
        data: {
          authenticity_token: csrfToken,
          attachment_ids: attachmentIds,
        },
        url: el.getAttribute("data-update-url"),
      });
      button.style.pointerEvents = "auto";
      if (button.value === 'Processing...' || button.innerText == 'Processing...') {
        button.value = 'Next'
        button.innerText = 'Finish'
      }
    }
  }

  /** *************
   * Removing uploaded attachments
   * **************/
  $(".c-multiImageUploader__sortContainer").on(
    "click",
    ".c-multiImageUploader__deleteIcon",
    function () {
      // Both persisted and unpersisted attachments will call this method to delete the attachment
      // Unpersisted attributes will not yet have their ID set in the delete URL (this is done in the
      // success callback of the upload function)
      const done = () => {
        $(this)
          .parent(".c-multiImageUploader__sortItem")
          .fadeOut(500, function () {
            $(this).remove();
          });
      };
      const url = $(this).parent().data("deleteUrl");
      if (!url.endsWith(":id:")) {
        // Persisted image, please delete
        $.ajax({
          type: "delete",
          url: $(this).parent().data("deleteUrl"),
          data: { authenticity_token: csrfToken },
        }).done(done);
      } else {
        done();
      }
    }
  );
}
