const deliveryCostRadio = document.querySelectorAll('.js-delivery-cost');
const totalCost = document.querySelector('.js-total-cost');
const paypalButton = document.querySelector('.js-paypal-button');
const symbol = document.querySelector('.js-currency-for-js');
const delivery = document.querySelector('.js-delivery-total');

const buttonFunctions = (el) => {
  if ( paypalButton ) {
    paypalButton.classList.remove('is-inactive');
  }
  const deliveryCost = el.getAttribute('data-delivery-cost') / 100;
  let itemCost = totalCost.getAttribute('data-item-cost') / 100;
  // Add tax if needed
  if (totalCost.getAttribute('data-tax-amount')) {
    itemCost += parseFloat(totalCost.getAttribute('data-tax-amount'))
  }

  const totalCostCalc = (parseFloat(deliveryCost) + parseFloat(itemCost)).toFixed(2);
  totalCost.innerHTML = totalCostCalc;
  delivery.innerHTML = parseFloat(deliveryCost).toFixed(2)
  symbol.classList.remove('hide');
}

if ( deliveryCostRadio.length > 0 ) {

  deliveryCostRadio.forEach(function (el) {
    if (el.checked) {
      buttonFunctions(el)
    }
    el.addEventListener('change', function() {
      buttonFunctions(el)
    });
  });

}
