$("#js-select2-artist_id select").select2({
  ajax: {
    url: "/artist_search",
    dataType: "json",
    delay: 250,
  },
  minimumInputLength: 1,
  placeholder: "Start typing the artist's name...",
  allowClear: true,
});
