const photoFileFields = document.querySelectorAll('.js-photoUpload--input');

function readURL(input) {

  if (input.files) {
    const parent = input.parentNode;
    const prodigiDiv = document.querySelector('.js-prodigi-preview ');
    const image = document.querySelector('.js-photoUpload--preview');
    const icon = parent.querySelector('.c-photoUpload__uploadIcon');
    const reader = new FileReader();

    reader.onload = function(e) {
      if (prodigiDiv) {
        prodigiDiv.style.backgroundImage = `url(${e.target.result})`;
        const pattern = document.querySelector('#pattern');
        if (pattern) {
          // Remove previously uploaded image first, if any
          const oldUpload = document.querySelector('#uploaded-image')
          if (oldUpload) {
            oldUpload.remove()
          }

          // Add uploaded image to pattern overlay
          const div = document.createElement('div')
          div.setAttribute("id","uploaded-image")
          div.style.backgroundImage = `url(${e.target.result})`;
          div.classList.add('c-prodigiPreview__circleBadgeTemplateUpload')
          pattern.parentNode.appendChild(div)
        }

      } else {
        image.src = e.target.result;
      }
      image.classList.add('hasPhoto');
      if (parent.classList.contains('c-photoUpload--additional')) {
        icon.classList.add('c-photoUpload__uploadIcon--close');
      }

      icon.addEventListener('click', function() {
        image.classList.remove('hasPhoto');
        image.src = '';
        input.value = '';
        if (parent.classList.contains('c-photoUpload--additional')) {
          icon.classList.remove('c-photoUpload__uploadIcon--close');
        }
      });
    };
    reader.readAsDataURL(input.files[0]);

  }
}

function addIcon(input) {
  const parent = input.parentNode;
  const e = document.createElement('span');
  e.classList.add('c-photoUpload__uploadIcon');
  parent.appendChild(e);
}

function cachedImage(input) {
  const prodigiDiv = document.querySelector('.js-prodigi-preview ');
  if (!prodigiDiv) {
    const parent = input.parentNode;
    const icon = parent.querySelector('.c-photoUpload__uploadIcon');
    const image = document.querySelector('.js-photoUpload--preview');

    if ( image.src.indexOf('.jpg') !== -1 || image.src.indexOf('.jpeg') !== -1 || image.src.indexOf('.png') !== -1 ) {
      image.classList.add('hasPhoto');
      icon.classList.add('c-photoUpload__uploadIcon--close');
    }

    icon.addEventListener('click', function () {
      image.classList.remove('hasPhoto');
      image.src = '';
      icon.classList.remove('c-photoUpload__uploadIcon--close');
      input.value = '';

      if (parent.classList.contains('c-photoUpload--additional')) {
        let destroy = parent.querySelector('.js-photoUpload__destroy');
        destroy.checked = true;
      } else {
        icon.classList.add('hide');
      }
    })
  }
}

function draggableUi(item) {
  const parent = item.parentNode;

  function enterHandler() {
    parent.classList.add('c-photoUpload--active');
  }
  function exitHandler() {
    parent.classList.remove('c-photoUpload--active');
  }

  item.addEventListener('dragover', enterHandler);
  item.addEventListener('dragenter', enterHandler);
  item.addEventListener('dragleave', exitHandler);
  item.addEventListener('dragend', exitHandler);
  item.addEventListener('drop', exitHandler);
}

photoFileFields.forEach(function (item) {

  addIcon(item);
  cachedImage(item);

  var div = document.createElement('div');
  const canDrag = ('draggable' in div) && ('ondragstart' in div && 'ondrop' in div);
  if (canDrag) {
    draggableUi(item);
  }

  item.addEventListener('change', function () {
    readURL(this);
  });

});
