const dropDown = document.querySelector('.js-dropdown-select-search')
const searchInput = document.querySelector('.js-search-input')

if (searchInput) {
  dropDown.addEventListener('change', (event) => {
    if (dropDown.value === "More" || dropDown.value === "Releases") {
      searchInput.placeholder = "Search any artist or title";
    } else if (dropDown.value === "Shops") {
      searchInput.placeholder = "Search by shop name";
    } else if (dropDown.value === "Labels") {
      searchInput.placeholder = "Search by label name";
    } else if (dropDown.value === "Stuff") {
      searchInput.placeholder = "Search any title, category or artist";
    }
    searchInput.focus()
  })
}
