const listingType = document.querySelector('.js-listing-type-select');

if (listingType) {

  const digital_actions = () => {
    document.querySelector('.js-file-attach').classList.remove('hide');
    document.querySelector('.js-condition').classList.add('hide');;
  }

  const non_digital_actions = () => {
    document.querySelector('.js-file-attach').classList.add('hide');
    document.querySelector('.js-condition').classList.remove('hide');;
  }

  listingType.addEventListener('change', () => {
    if (listingType.value === 'print_downloads_pdf') {
      digital_actions()
    } else {
      non_digital_actions()
    }
  })

  if (listingType.value === 'print_downloads_pdf') {
    digital_actions()
  } else {
    non_digital_actions()
  }

}

