import $ from 'jquery';

if ($('.js-modalOpen').length > 0) {
  $('.js-modalOpen').foundation('open');

  const form = document.querySelector('.edit_user');
  const actions = document.getElementById('messages');

  let message = "";

  form.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    actions.innerHTML = `<span class="c-formMessage c-formMessage--success">${message}</span>`;

    setTimeout(() => {
      $('.js-modalOpen').foundation('close');
    }, 1000);
  });

  form.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.username[0];
    actions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });
}
