const expression = /^[a-z0-9_]{3,16}$/i;
const usernameInput = document.querySelector('.js-username-input');

if (usernameInput) {
  const description = document.querySelector('.js-username-description');

  const checkUsername = event => {
    const validUsername = usernameInput.value.match(expression);
    if(validUsername == null) {
      usernameInput.classList.add("c-validation__border");
      description.classList.add("c-validation__errorMessage");
    } else if(usernameInput.value == ""){
      usernameInput.classList.add("c-validation__border");
      description.classList.add("c-validation__errorMessage");
    } else{
      usernameInput.classList.remove("c-validation__border");
      description.classList.remove("c-validation__errorMessage");
    }
  }

  usernameInput.addEventListener("blur", () => {
    checkUsername(event)
  });
}
