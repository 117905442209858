import $ from 'jquery';

const setupsaleTypeTriggers = (() => {

  const auctionStartTriggers = document.querySelectorAll('.js-auctionStartTrigger');

  if (!auctionStartTriggers) return;

  auctionStartTriggers.forEach(trigger => {

    trigger.addEventListener('click', (event) => {
      $('#js-auctionStartModal').foundation('open');
      const id = event.currentTarget.dataset.formAction
      const currency = event.currentTarget.dataset.currency
      document.querySelectorAll('.js-inputCurrency').forEach(function(element) {
        element.innerHTML = currency
      })
      document.getElementById('new_auction').action = `/listings/${id}/auctions`;
    })
  });

})();


// Form handling
const auctionFormHandling = (() => {

  const auctionForm = document.getElementById('new_auction');
  const auctionActions = document.getElementById('auctionActions');

  if( !auctionForm ) return;

  let message = "";

  auctionForm.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    auctionActions.innerHTML = `<span class="c-formMessage">${message}</span>`;

    setTimeout(() => {
      location.reload();
    }, 300);
  });

  auctionForm.addEventListener('ajax:error', event => {
    message = Object.entries(event.detail[0].errors)
      .map(([_key, val]) => val)
      .join("<br />");
    auctionActions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });

})();
