import $ from "jquery";

const typesWithProperites = ['speakers', 'tshirts']
$("select#js-listing-type-change").on("change", function (e) {
  $("#js-custom-properties-container")
    .html("<img src='/loading.svg' width='50' height='50' />")
    .load(
      "/listings/listing_properties?type=" +
        e.target.value +
        " #form_contents"
    );
  if (typesWithProperites.includes(e.target.value)) {
    $(".js-custom-header").removeClass('hide')
    $(".js-custom-fields").removeClass('hide')
  } else {
    $(".js-custom-header").addClass('hide')
    $(".js-custom-fields").addClass('hide')
  }
});
