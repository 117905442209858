const otherFormatDetails = document.querySelector('.other-format-js');
const fileFormat = document.querySelector('.file-format-js');
const fileUploader = document.querySelector('.bulk-listing-file-js');
const fileLabel = document.querySelector('.bulk-listing-file-label-js');

if (otherFormatDetails) {

  const showExtraDetails = () => {
    otherFormatDetails.classList.remove("hide");
  }

  const hideExtraDetails = () => {
    otherFormatDetails.classList.add("hide");
  }

  fileFormat.addEventListener("change", () => {
    if (fileFormat.value == 'Other') {
      showExtraDetails()
    }
    else {
      hideExtraDetails()
    }
  });
}

if (fileUploader) {
  const updateFileName = () => {
    fileLabel.innerText = fileUploader.files[0].name
  }

  fileUploader.addEventListener("change", () => {
    updateFileName()
  })
}
