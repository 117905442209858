const conditionSelectors = document.querySelectorAll('.js-discCondition');

function conditionDiscToolTip(selector) {
  const discToolTipContainer = document.querySelector('.c-condition__discToolTip');

  discToolTipContainer.classList.remove('hide');
  discToolTipContainer.append('.c-condition__discToolTipArrow');

  let parent = selector.parentNode;
  let title = parent.querySelector(".js-discToolTip__title").innerText;
  let description = parent.querySelector(".js-discToolTip__description").innerText;

  let discToolTipContent = `
    <h6>${title}</h6>
    <p>${description}</p>
  `;

  discToolTipContainer.innerHTML = discToolTipContent;
}

conditionSelectors.forEach(function(item) {
  item.addEventListener("change", function() {
    conditionDiscToolTip(item);
  });
});
