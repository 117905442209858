const currencyFields = document.querySelectorAll('.js-input--currency');
const currencySelect = document.querySelector('.js-currency--select');
const symbols = document.querySelectorAll('.js-currency--symbol');

if (currencyFields.length > 0) {

  currencyFields.forEach(function (field) {

    field.addEventListener('blur', function () {
      field.value = parseFloat(field.value).toFixed(2);
    });

  });

}

if (currencySelect) {

  const updateCurrency = event => {
    if (currencySelect.value === 'GBP') {
      symbols.forEach((symbol) => {
        symbol.textContent = '£'
      });
    }
    else if (currencySelect.value === 'USD') {
      symbols.forEach((symbol) => {
        symbol.textContent = '$'
      });
    }
    else if (currencySelect.value === 'EUR') {
      symbols.forEach((symbol) => {
        symbol.textContent = '€'
      });
    }
    else if (currencySelect.value === 'AUD') {
      symbols.forEach((symbol) => {
        symbol.textContent = 'A$'
      });
    }
  }

  window.addEventListener('load', () => {
    updateCurrency(event);
  });

  currencySelect.addEventListener('change', () => {
    updateCurrency(event)
  })

}
