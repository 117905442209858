const countryCheckBoxes = document.querySelectorAll('.js-country-check-box');

if (countryCheckBoxes.length > 0) {

  countryCheckBoxes.forEach(el => {
    if (el.checked) {
      el.parentElement.classList.add('c-filters__countryCheckbox--active')
    }
    el.addEventListener('change', () => {
      if (el.checked) {
        countryCheckBoxes.forEach(a => {
          if (a != el) {
            a.parentElement.classList.remove('c-filters__countryCheckbox--active')
          }
        })
        el.parentElement.classList.add('c-filters__countryCheckbox--active')
      } else {
        el.parentElement.classList.remove('c-filters__countryCheckbox--active')
      }
    })
  })
}
