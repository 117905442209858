import $ from 'jquery';

const GBPForm = document.querySelectorAll('.js-GBP-form')
const USDForm = document.querySelectorAll('.js-USD-form')
const EURForm = document.querySelectorAll('.js-EUR-form')
const AUDForm = document.querySelectorAll('.js-AUD-form')
let shippingValue = 0

if (GBPForm.length > 0) {
  const GBP = 'GBP'
  shippingValue = 0

  function GBPCalculator(arg, currency) {
    let subTotal = document.querySelector(`.js-${currency}-sub-total`)
    let shippingTotal = document.querySelector(`.js-${currency}-shipping-total`)
    let totalPrice = document.querySelector(`.js-${currency}-total-price`)
    let taxTotal = document.querySelector(`.js-${currency}-tax-price`)?.innerText || '0.00'
    if ( $( arg ).is(':checked') ) {
      shippingValue += (parseFloat(arg.value/100))
      shippingTotal.innerText = shippingValue.toFixed(2)
      totalPrice.innerText = (parseFloat(shippingTotal.innerText) + parseFloat(subTotal.innerText) + parseFloat(taxTotal)).toFixed(2)
    }
  }

  $(`input.js-GBP-radio-button`).each(function() {
    GBPCalculator(this, GBP)
  });

  $(`input.js-GBP-radio-button`).change(function() {
    shippingValue = 0
    $(`input.js-GBP-radio-button`).each(function() {
      GBPCalculator(this, GBP)
    });
  })
}

if (USDForm.length > 0) {
  const USD = 'USD'
  shippingValue = 0

  function USDCalculator(arg, currency) {
    let subTotal = document.querySelector(`.js-${currency}-sub-total`)
    let shippingTotal = document.querySelector(`.js-${currency}-shipping-total`)
    let totalPrice = document.querySelector(`.js-${currency}-total-price`)
    let taxTotal = document.querySelector(`.js-${currency}-tax-price`)?.innerText || '0.00'
    if ( $( arg ).is(':checked') ) {
      shippingValue += (parseFloat(arg.value/100))
      shippingTotal.innerText = shippingValue.toFixed(2)
      totalPrice.innerText = (parseFloat(shippingTotal.innerText) + parseFloat(subTotal.innerText) + parseFloat(taxTotal)).toFixed(2)
    }
  }

  $(`input.js-USD-radio-button`).each(function() {
    USDCalculator(this, USD)
  });

  $(`input.js-USD-radio-button`).change(function() {
    shippingValue = 0
    $(`input.js-USD-radio-button`).each(function() {
      USDCalculator(this, USD)
    });
  })
}

if (EURForm.length > 0) {
  const EUR = 'EUR'
  shippingValue = 0

  function EURCalculator(arg, currency) {
    let subTotal = document.querySelector(`.js-${currency}-sub-total`)
    let shippingTotal = document.querySelector(`.js-${currency}-shipping-total`)
    let totalPrice = document.querySelector(`.js-${currency}-total-price`)
    let taxTotal = document.querySelector(`.js-${currency}-tax-price`)?.innerText || '0.00'
    if ( $( arg ).is(':checked') ) {
      shippingValue += (parseFloat(arg.value/100))
      shippingTotal.innerText = shippingValue.toFixed(2)
      totalPrice.innerText = (parseFloat(shippingTotal.innerText) + parseFloat(subTotal.innerText) + parseFloat(taxTotal)).toFixed(2)
    }
  }

  $(`input.js-EUR-radio-button`).each(function() {
    EURCalculator(this, EUR)
  });

  $(`input.js-EUR-radio-button`).change(function() {
    shippingValue = 0
    $(`input.js-EUR-radio-button`).each(function() {
      EURCalculator(this, EUR)
    });
  })
}

if (AUDForm.length > 0) {
  const AUD = 'AUD'
  shippingValue = 0

  function AUDCalculator(arg, currency) {
    let subTotal = document.querySelector(`.js-${currency}-sub-total`)
    let shippingTotal = document.querySelector(`.js-${currency}-shipping-total`)
    let totalPrice = document.querySelector(`.js-${currency}-total-price`)
    let taxTotal = document.querySelector(`.js-${currency}-tax-price`)?.innerText || '0.00'
    if ( $( arg ).is(':checked') ) {
      shippingValue += (parseFloat(arg.value/100))
      shippingTotal.innerText = shippingValue.toFixed(2)
      totalPrice.innerText = (parseFloat(shippingTotal.innerText) + parseFloat(subTotal.innerText) + parseFloat(taxTotal)).toFixed(2)
    }
  }

  $(`input.js-AUD-radio-button`).each(function() {
    AUDCalculator(this, AUD)
  });

  $(`input.js-AUD-radio-button`).change(function() {
    shippingValue = 0
    $(`input.js-AUD-radio-button`).each(function() {
      AUDCalculator(this, AUD)
    });
  })
}
