const firstReleaseType = document.querySelector('.js-release-type-1');
const secondReleaseType = document.querySelector('.js-release-type-2');
const format = document.querySelector('.js-detailsFormat');
const formatDiv = document.querySelector('.js-details-div');

if (firstReleaseType) {
  if (firstReleaseType.innerText == '78') {
    format.value = '78'
    formatDiv.classList.add('hide')
  }
}
if (secondReleaseType) {
  const updateFormat = event => {
    if (secondReleaseType.value == '78') {
      format.value = '78'
      formatDiv.classList.add('hide')
    }
    else {
      formatDiv.classList.remove('hide')
    }
  }
  secondReleaseType.addEventListener("change", () => {
    updateFormat(event)
  });
}
