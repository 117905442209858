const buttons = document.querySelectorAll('.js-answer-button')
const editQuestionButtons = document.querySelectorAll('.js-edit-question-button')

if (buttons.length > 0) {
  buttons.forEach(trigger => {
    trigger.addEventListener('click', (event) => {
      const questionId = trigger.getAttribute('value')
      const textArea = document.querySelector(`#js-answer-textbox-${questionId}`)
      textArea.classList.toggle('hide');
    })
  });
}

if (editQuestionButtons.length > 0) {
  editQuestionButtons.forEach(trigger => {
    trigger.addEventListener('click', (event) => {
      const questionId = trigger.getAttribute('value')
      const textArea = document.querySelector(`#js-question-edit-textbox-${questionId}`)
      textArea.classList.toggle('hide');
    })
  });
}
