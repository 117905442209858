const paypalButtonContainer = document.querySelector('.js-paypal-button');

if (paypalButtonContainer) {
  const listingId = document.querySelector('.js-listing-id').value;
  const errorDiv = document.querySelector('#paypal-error-js');

  paypal.Buttons({

    style: {
      layout: 'horizontal',
      size: 'responsive',
      height: 45,
      color: 'gold',
      shape: 'pill',
      label: 'pay',
      tagline: 'false'
    },

    createOrder: function () {
      const shipping_option = document.querySelector('input[name="payment[shipping_option]"]:checked').value;
      const shipping_cost_cents = document.querySelector('input[name="payment[shipping_option]"]:checked').dataset.deliveryCost;

      return fetch(`/payments/listing_payments`, {
        method: 'post',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          payment: {
            shipping_option: shipping_option,
            listing_id: listingId,
            shipping_cost_cents: shipping_cost_cents
          }
        })
      }).then(function (res) {
        return res.json();
      }).then(function (data) {
        if (data.orderID) {
          return data.orderID;
        } else {
          const span = document.createElement("span")
          span.className = "c-formMessage c-formMessage--error"
          span.innerText = "There was an error loading the checkout."
          errorDiv.prepend(span)
          throw new Error(data.error['payment']);
        }
      });
    },

    onShippingChange: function (data, actions) {
      return fetch(`/payments/listing_payments/shipping_review`, {
        method: 'put',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          data: data
        })
      }).then(res => {
        if (!res || res.ok === false) {
          return actions.reject();
        }
        return actions.resolve();
      });
    },

    onApprove: function (data) {
      return fetch(`/payments/listing_payments/execute`, {
        method: 'put',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          orderID: data.orderID,
          listing_id: listingId
        })
      }).then(function (res) {
        return window.location.replace('/user_profile/purchases');
      });
    },

    onCancel: function (data) {
      return fetch(`/payments/listing_payments/cancel`, {
        method: 'delete',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          orderID: data.orderID
        })
      }).then(function (res) {
        return res.json();
      });
    }
  }).render('.js-paypal-button');
}
