const previewImage = document.querySelector('.js-prodigi-preview');
const fitAreaSelect = document.querySelector('.js-fit-area');
const fillAreaSelect = document.querySelector('.js-fill-area');
const printSizeDescription = document.querySelector('.js-print-size-description');

if (previewImage) {
  previewImage.style.opacity = 1
  const objectFitContain = () => {
    previewImage.style.backgroundSize = 'contain'
    const patternImage = document.querySelector('#uploaded-image');
    if (patternImage) {
      patternImage.style.backgroundSize = 'contain'
    }
    printSizeDescription.innerHTML = 'Your image will be shrunk until the whole image fits within the print area of the product, whilst retaining the aspect ratio of your image. This will usually mean there is white space at the top/bottom or left/right edges.'
  }

  const objectFitCover = () => {
    previewImage.style.backgroundSize = 'cover'
    const patternImage = document.querySelector('#uploaded-image');
    if (patternImage) {
      patternImage.style.backgroundSize = 'cover'
    }
    printSizeDescription.innerHTML = 'Your image will be centred and cropped so that it exactly fits the aspect ratio (height divided by width) of the printing area of the product you chose. Your image will cover all of the product print area.'
  }

  if (fitAreaSelect.checked) {
    objectFitContain()
  } else {
    objectFitCover()
  }

  fitAreaSelect.addEventListener('change', function() {
    if (this.checked) {
      objectFitContain()
    }
  })
  fillAreaSelect.addEventListener('change', function() {
    if (this.checked) {
      objectFitCover()
    }
  })

}
