import $ from 'jquery';

const setupRatingTriggers = (() => {

  const userRatingTriggers = document.querySelectorAll('.js-userRatingTrigger');

  if (!userRatingTriggers) return;

  userRatingTriggers.forEach(trigger => {

    trigger.addEventListener('click', (event) => {
      $('#js-userRatingModal').foundation('open');
      const id = event.currentTarget.dataset.formAction
      const rating_form = document.getElementById('new_rating')
      if (rating_form) {
        rating_form.action = `/listings/${id}/ratings`;
      }
    })
  });

})();

// Form handling
const ratingFormHandling = (() => {

  const ratingform = document.getElementById('new_rating');
  const ratingActions = document.getElementById('UserRatingActions');

  if( !ratingform ) return;

  let message = "";

  ratingform.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    ratingActions.innerHTML = `<span class="c-formMessage">${message}</span>`;

    setTimeout(() => {
      location.reload();
    }, 300);
  });

  ratingform.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.description;
    ratingActions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });

})();
