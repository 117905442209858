const toggleButtons = document.querySelectorAll('.js-wantlist-toggle');
if (toggleButtons.length > 0) {

  const wantDetails = {
    addText: 'Add to Wantlist',
    removeText: 'Remove from Wantlist',
    addMethod: 'post',
    removeMethod: 'delete',
    addedClass: 'is-added',
  };

  function addedWantlist(el, button_path) {
    const text = el.querySelector('span');
    el.classList.add(wantDetails.addedClass);
    el.setAttribute('data-method', wantDetails.removeMethod);
    el.setAttribute('href', button_path);
    text.innerHTML = wantDetails.removeText;
  }

  function removedWantlist(el, button_path) {
    const text = el.querySelector('span');
    el.classList.remove(wantDetails.addedClass);
    el.setAttribute('data-method', wantDetails.addMethod);
    el.setAttribute('href', button_path);
    text.innerHTML = wantDetails.addText;
  }

  toggleButtons.forEach(function (button) {
    button.addEventListener('click', function (e) {
      e.preventDefault();
    });
  });

  document.body.addEventListener('ajax:success', function (event) {
    const detail = event.detail;
    const status = detail[0].success;
    const id = detail[0].id.match(/^[^-\n]+/)[0];
    const button_path = detail[0].button_path;

    toggleButtons.forEach(function (el) {
      if (el.dataset.versionId === id) {
        if (status === 'added') {
          addedWantlist(el, button_path);
        } else if (status === 'removed') {
          removedWantlist(el, button_path);
        }
      };
    });

  });

}
