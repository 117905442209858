import $ from 'jquery';
import slick from 'slick-slider';

const $slider = $('.js-listingCarousel');
const $sliderNav = $('.js-listingCarouselThumbs');

const $versionSlider = $('.js-versionCarousel');
const $versionSliderNav = $('.js-versionCarouselThumbs');

if ( $slider ) {

  $slider.slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false,
    asNavFor: $sliderNav
  });

  $sliderNav.slick({
    arrows: false,
    accessibility: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    infinite: false,
    asNavFor: $slider
  });

}

if ($versionSlider) {
  $versionSlider.slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    asNavFor: $versionSliderNav
  });

  $versionSliderNav.slick({
    arrows: false,
    accessibility: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    infinite: false,
    asNavFor: $versionSlider,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
        }
      }
    ]
  });

}
