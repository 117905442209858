import $ from 'jquery';

const freePostageButton = document.querySelector('.js-free-postage')

if (freePostageButton) {
  freePostageButton.addEventListener('click', function() {
    $('.js-delivery-field').each(function() {
      this.value = 0.00
    })
  })
}
