const openIcons = document.querySelectorAll('.js-listing-modal-open');
const closeIcons = document.querySelectorAll('.js-listing-modal-close');
const indexContent = document.querySelector('.js-listing-section');
const sellerContent = document.querySelector('.js-seller-section-one');
const sellerContentTwo = document.querySelector('.js-seller-section-two');
const nav = document.querySelector('.js-modal-nav');
const footer = document.querySelector('.js-page-footer');
const filters = document.querySelector('.js-filter-section');

const indexClassMovement = () => {
  openIcons.forEach((icon) => {
    icon.addEventListener("click", () => {
      nav.classList.add("c-listingIndexModal__backgroundBlur");
      indexContent.classList.add("c-listingIndexModal__backgroundBlur");
      filters.classList.add("c-listingIndexModal__backgroundBlur");
      footer.classList.add("c-listingIndexModal__backgroundBlur");
    });
  })
  closeIcons.forEach((icon) => {
    icon.addEventListener("click", () => {
      nav.classList.remove("c-listingIndexModal__backgroundBlur");
      indexContent.classList.remove("c-listingIndexModal__backgroundBlur");
      filters.classList.remove("c-listingIndexModal__backgroundBlur");
      footer.classList.remove("c-listingIndexModal__backgroundBlur");
    });
  })
}

const sellerClassMovement = () => {
  openIcons.forEach((icon) => {
    icon.addEventListener("click", () => {
      nav.classList.add("c-listingIndexModal__backgroundBlur");
      sellerContent.classList.add("c-listingIndexModal__backgroundBlur");
      sellerContentTwo.classList.add("c-listingIndexModal__backgroundBlur");
      footer.classList.add("c-listingIndexModal__backgroundBlur");
    });
  })
  closeIcons.forEach((icon) => {
    icon.addEventListener("click", () => {
      nav.classList.remove("c-listingIndexModal__backgroundBlur");
      sellerContent.classList.remove("c-listingIndexModal__backgroundBlur");
      sellerContentTwo.classList.remove("c-listingIndexModal__backgroundBlur");
      footer.classList.remove("c-listingIndexModal__backgroundBlur");
    });
  })
}

if (indexContent) {
  indexClassMovement()
}

if (sellerContent) {
  sellerClassMovement()
}

