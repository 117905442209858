import $ from 'jquery';

$("#merge-link").click(function() {
  const master_ids = []
    $('input[type=checkbox]:checked').each(function(){
      master_ids.push($(this).val());
    });
  $(this).attr("href", "/vinyl-records/new_merge?master_ids=" + encodeURIComponent(master_ids));
});

$("#merge-version-link").click(function() {
  const version_ids = []
    $('input[type=checkbox]:checked').each(function(){
      version_ids.push($(this).val());
    });
  $(this).attr("href", "/versions/new_merge?version_ids=" + encodeURIComponent(version_ids));
});

$("#merge-label-link").click(function() {
  const label_ids = []
    $('input[type=checkbox]:checked').each(function(){
      label_ids.push($(this).val());
    });
  $(this).attr("href", "/labels/new_merge?label_ids=" + encodeURIComponent(label_ids));
});
