const expression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const input = document.querySelector('.js-password-input');

if (input) {
  const confirmInput = document.querySelector('.js-confirm-input');
  const description = document.querySelector('.js-password-description');
  const alert = document.querySelector('.js-password-alert-text');

  const checkPassword = event => {
    const validPassword = input.value.match(expression);
    if(validPassword == null) {
      input.classList.add("c-validation__border");
      description.classList.add("c-validation__errorMessage");
    } else if(input.value == ""){
      input.classList.add("c-validation__border");
      description.classList.add("c-validation__errorMessage");
    } else{
      input.classList.remove("c-validation__border");
      description.classList.remove("c-validation__errorMessage");
    }
  }

  input.addEventListener("blur", () => {
    checkPassword(event)
  });

  const confirmPassword = event => {
    if(input.value != confirmInput.value) {
      confirmInput.classList.add("c-validation__border");
      alert.classList.remove("hide");
      alert.classList.add("c-validation__errorMessage");
    } else{
      confirmInput.classList.remove("c-validation__border");
      alert.classList.add("hide");
    }
  }

  confirmInput.addEventListener("blur", () => {
    confirmPassword(event)
  });
}
