import $ from 'jquery';

$(document).ready(function() {
  if($('.whatsapp').length > 0) {
    $('.whatsapp').on("click", function(e) {
            var article = $(this).attr("data-text");
            var weburl = $(this).attr("data-link");
            var whats_app_message = encodeURIComponent(document.URL);
            var whatsapp_url = "whatsapp://send?text="+whats_app_message;
            window.location.href= whatsapp_url;
    });
  };
});
