const prompt = document.querySelector('.js-resolve-issue-prompt')

if (prompt) {
  const rating = document.querySelector('.js-star-rating')
  const fiveStar = document.querySelector('#rating_value_5')
  rating.addEventListener('change', () => {
    if (fiveStar.checked) {
      prompt.classList.add("hide")
    } else {
      prompt.classList.remove("hide")
    }
  })
}
