import Drift from "drift-zoom";

// Listing images on Listing pages will have the Drift zooming applied
const targetContainer = document.querySelector(".js-zoomTarget");
const driftableImages = document.querySelectorAll(
  ".c-listing__carouselMain img[data-zoom]"
);

// Add styles to position the zoom pane over the top of the right side content
const toggleShow = () => {
  targetContainer.classList.add("js-zoomTarget-open");
};

const toggleHide = () => {
  targetContainer.classList.remove("js-zoomTarget-open");
};

driftableImages.forEach((image) => {
  new Drift(image, {
    paneContainer: targetContainer,
    hoverBoundingBox: true,
    includeBasicStyles: false,
    zoomFactor: 5,
    hoverDelay: 250,
    onShow: toggleShow,
    onHide: toggleHide,
  });
});
