import $ from 'jquery';

$(document).ready(function() {
  const container = $('.js-featured-listing-more-info')

  function appendButton() {
    if (container.height() === 40) {
      container.after('<p class="c-shop__readMore js-read-button">Read More</p>')
    }
  }

  appendButton()

  if ($('.js-read-button')) {
    $('.js-read-button').click(function() {
      container.toggleClass('c-shop__maxHeight');
      if (container.height() > 40) {
        this.innerHTML = 'Read Less'
      } else {
        this.innerHTML = 'Read More'
      }
    })
  }
});
