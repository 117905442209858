import $ from 'jquery';

$(document).focusin(function(e) {
  $('.artistError').hide();
});

$('.js-detailsTitle').change(function(e) {
  $('.titleError').hide();
});

$('.js-detailsReleaseYear').change(function(e) {
  $('.releaseYearError').hide();
});

$('.js-detailsReleaseType').change(function(e) {
  $('.releaseTypeError').hide();
  if ($('.js-detailsReleaseType').children("option:selected").val() == 'Single') {
    $('.js-mix').addClass('hide')
  } else {
    $('.js-mix').removeClass('hide')
  }
});

$('.js-detailsOriginalCountry').change(function(e) {
  $('.originalCountryError').hide();
});

$('.js-detailsVersionCountry').change(function(e) {
  $('.versionCountryError').hide();
});

$('.js-detailsVersionYear').change(function(e) {
  $('.versionYearError').hide();
});

$(document).focusin(function(e) {
  $('.labelError').hide();
});

$('.js-detailsFormat').change(function(e) {
  $('.formatError').hide();
});

$('.js-detailsMix').change(function(e) {
  $('.mixError').hide();
});

// Checks release type again if user goes back steps
$( document ).ready(function(e) {
  if ($('.js-detailsReleaseType').children("option:selected").val() == 'Single') {
    $('.js-mix').addClass('hide')
  }
});

