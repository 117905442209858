const taxInfoDiv = document.querySelector('.js-tax-info');
const newBid = document.querySelector('.js-bid-new');

if ( newBid ) {

  newBid.addEventListener('focus', function(e) {
    taxInfoDiv.classList.remove('hide')
  })

}
