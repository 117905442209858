import $ from 'jquery';

const input = document.querySelector('.js-search-input')
const searchBar = document.querySelector('.js-search-bar')

if ( input ) {

  // Delay request until user finishes typing
  const delayKeyUp = (() => {
    let timer = null;
    const delay = (func, ms) => {
        timer ? clearTimeout(timer): null
        timer = setTimeout(func, ms)
    }
    return delay
  })();

  // Hide results if clicked elsewhere on page
  const hideResults = () => {
    $(window).click(function() {
      if (!$(event.target).is($('.c-search__tabs--active'))) {
        $('.c-search__resultsWrap').remove()
      }
    });
  }

  const tabChanger = (tabs) => {
    tabs.setAttribute('data-active-tab', 'Everything')
    tabs.children.forEach(tab=> {
      tab.addEventListener('click', (e) => {
        e.preventDefault()
        tabs.setAttribute('data-active-tab', tab.innerText)
        tabs.children.forEach(tab=> {
          tab.className = ''
        })
        tab.className = 'c-search__tabs--active'
        fetchResults(input.value, tab.innerText)
      })
    })
  }

  const createTabs = (div) => {
    let tabs = document.createElement("ul");
    tabs.className = 'c-search__tabs'
    div.appendChild(tabs)
    createTabItem(tabs, 'Everything')
    createTabItem(tabs, 'Artists')
    createTabItem(tabs, 'Masters')
    createTabItem(tabs, 'Labels')
    createTabItem(tabs, 'Shops')
    createTabItem(tabs, 'Listings')
    tabChanger(tabs)
  }

  const createTabItem = (tabs, name) => {
    let listEl = document.createElement("li")
    tabs.appendChild(listEl)
    listEl.innerText = name
    if (name === 'Everything') {
      listEl.className = 'c-search__tabs--active'
    }
  }

  const createElements = (results) => {
    // Dropdown
    let div = document.querySelector('.c-search__resultsWrap') || document.createElement("div");
    div.className = 'c-search__resultsWrap'
    searchBar.appendChild(div)

    if (!document.querySelector('.c-search__tabs')) {
      createTabs(div)
    }

    const itemWrap = document.querySelector('.c-search__itemWrap') || document.createElement("div")
    itemWrap.className = 'c-search__itemWrap'
    div.appendChild(itemWrap)
    itemWrap.replaceChildren()

    if (results.length > 0) {
      results.forEach(function(result) {

        // Link
        let a = document.createElement("a");
        itemWrap.appendChild(a)
        a.href = `${result.path}?search_id=${result.search_id}`;

        // Flex wrap
        let flex = document.createElement("div");
        flex.className = 'c-search__flex'
        a.appendChild(flex)

        // Image
        let image = document.createElement("img")
        image.src = result.image
        flex.appendChild(image)

        // Info div
        let infoDiv = document.createElement("div");
        flex.appendChild(infoDiv)

        // Title
        let title = document.createElement("p")
        title.innerText = result.title
        infoDiv.appendChild(title)

        // Model
        let modelName = document.createElement("p")
        modelName.innerText = result.model
        modelName.className = 'c-search__modelName'
        infoDiv.appendChild(modelName)
      })

    } else {
      let noResults = document.createElement("p")
      noResults.innerText = 'No results found'
      itemWrap.appendChild(noResults)
    }
    hideResults()
  }


  // Make AJAX call to retreive results, and display on page
  const fetchResults = (searchTerm, activeTab) => {
    let nowTab =
    fetch(`/search_bar/${activeTab.toLowerCase()}?query=${searchTerm}`, { headers: { "Content-Type": "application/json; charset=utf-8" }})
      .then(res => res.json())
      .then(response => {
        createElements(response.results)
    })
    .catch(err => {
      console.log(err)
        console.log('error')
    });

  }


  // Show results after user stops typing
  input.addEventListener('keyup', () => {
    const tabs = document.querySelector('.c-search__tabs')

    let activeTab = 'Everything'

    if (tabs) {
      let activeTab = document.querySelector('.c-search__tabs').getAttribute('data-active-tab')
    }
    const searchTerm = input.value
    delayKeyUp(() => {fetchResults(searchTerm, activeTab)}, 400);
  })

  // Show results if user clicks back into search bar and a value is present
  input.addEventListener('focus', () => {
    const tabs = document.querySelector('.c-search__tabs')

    let activeTab = 'Everything'

    if (tabs) {
      let activeTab = document.querySelector('.c-search__tabs').getAttribute('data-active-tab')
    }
    const searchTerm = input.value
    if (searchTerm.length > 0) {
      fetchResults(searchTerm, activeTab)
    }
  })

}
