const cookieButton = document.querySelector('.js-cookie-accept');

if ( cookieButton ) {

  const cookieBanner = document.querySelector('.js-cookie-policy');

  if (document.cookie.indexOf('cookiePolicy') < 0) {
    setTimeout(function(){
      cookieBanner.classList.remove('is-inactive');
    }, 2000);

    cookieButton.addEventListener('click', function() {
      document.cookie = 'cookiePolicy=agreed;max-age=31536000';
      cookieBanner.classList.add('is-inactive');
    });
  }
}
