const title = document.querySelector('.js-title-input');

if (title) {
  const description = document.querySelector('.js-title-description');

  const checkLength = () => {
    const inputLength = title.value.length;
    document.querySelector('.js-character-count').innerText = inputLength
    if (inputLength > 50) {
      title.classList.add("c-validation__border");
      description.classList.add("c-validation__errorMessage");
    } else if (inputLength < 50 && title.classList.contains("c-validation__border")) {
      title.classList.remove("c-validation__border");
      description.classList.remove("c-validation__errorMessage");
    }
  }

  title.addEventListener("keyup", () => {
    checkLength()
  });
}
