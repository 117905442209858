// Coped over from when this file was in the asset pipeline

$(function () {
  $(".dropdown_selector").select2();

  /* Drag and drop ArtistMember sorting when editing an Artist */
  $(".sortable").sortable({
    handle: ".handle",
    update: function (e, ui) {
      ui.item
        .parent()
        .find(".position")
        .map(function (index, elem) {
          $(elem).attr("value", index + 1);
        });
    },
  });

  $(".sortable").on(
    "cocoon:after-insert",
    function (e, insertedItem, originalEvent) {
      setupArtistSelect(insertedItem.find(".artist-select2"), true);
    }
  );
});

$('.toggle_status').click(function () {
  location.reload();
});

$('.approve_contribution').click(function () {
  location.reload();
});

// Artist Ajax Select
if ($('.artist-select2').length > 0) {
  setupArtistSelect($('.artist-select2'), $('.artist-select2').first().hasClass("js-allow-creation"));
}

function setupArtistSelect(selector, allowsCreation) {
  selector.select2({
    tags: allowsCreation,
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to artists_controller to get artist list
    ajax: {
      url: '/artist_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  })

  if (selector.select2('data').length > 0) {
    const artistData = selector.select2('data')
    $( ".aritst-hidden-field-js" ).empty()
    $( ".aritst-hidden-field-js" )[0].value = artistData[0].id
  }

  selector.on("select2:close", function() {
    const artistData = selector.select2('data')
    $( ".aritst-hidden-field-js" ).empty()
    $( ".aritst-hidden-field-js" )[0].value = artistData[0].id
  })
}

// Label Ajax Select
if ($('.label-select2').length > 0) {

  $('.label-select2').select2({
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to labels_controller to get label list
    ajax: {
      url: '/label_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  });

  if ($('.label-select2').select2('data').length > 0) {
    const labelData = $('.label-select2').select2('data')
    $( ".label-hidden-field-js" ).empty()
    $( ".label-hidden-field-js" )[0].value = labelData[0].id
  }

  $('.label-select2').on("select2:close", function() {
    const labelData = $('.label-select2').select2('data')
    $( ".label-hidden-field-js" ).empty()
    $( ".label-hidden-field-js" )[0].value = labelData[0].id
  })

}

// Master Ajax Select
if ($('.master-select2').length > 0) {

  $('.master-select2').select2({
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to masters_controller to get master list
    ajax: {
      url: '/master_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  });

  if ($('.master-select2').select2('data').length > 0) {
    const masterData = $('.master-select2').select2('data')
    $( ".master-hidden-field-js" ).empty()
    $( ".master-hidden-field-js" )[0].value = masterData[0].id
  }

  $('.master-select2').on("select2:close", function() {
    const masterData = $('.master-select2').select2('data')
    $( ".master-hidden-field-js" ).empty()
    $( ".master-hidden-field-js" )[0].value = masterData[0].id
  })

}

// Contributor Ajax Select
if ($('.contributor-select2').length > 0) {

  $('.contributor-select2').select2({
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to users_controller to get user list
    ajax: {
      url: '/user_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  });

  if ($('.contributor-select2').select2('data').length > 0) {
    const contributorData = $('.contributor-select2').select2('data')
    $( ".contributor-hidden-field-js" ).empty()
    $( ".contributor-hidden-field-js" )[0].value = contributorData[0].id
  }

  $('.contributor-select2').on("select2:close", function() {
    const contributorData = $('.contributor-select2').select2('data')
    $( ".contributor-hidden-field-js" ).empty()
    $( ".contributor-hidden-field-js" )[0].value = contributorData[0].id
  })

}

// PhotoContributor Ajax Select
if ($('.photo-contributor-select2').length > 0) {

  $('.photo-contributor-select2').select2({
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to users_controller to get user list
    ajax: {
      url: '/user_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  });

  if ($('.photo-contributor-select2').select2('data').length > 0) {
    const photoContributorData = $('.photo-contributor-select2').select2('data')
    $( ".photo-contributor-hidden-field-js" ).empty()
    $( ".photo-contributor-hidden-field-js" )[0].value = photoContributorData[0].id
  }

  $('.photo-contributor-select2').on("select2:close", function() {
    const photoContributorData = $('.photo-contributor-select2').select2('data')
    $( ".photo-contributor-hidden-field-js" ).empty()
    $( ".photo-contributor-hidden-field-js" )[0].value = photoContributorData[0].id
  })

}

// ShopOwners Ajax Select
if ($('.shop-owners-select2').length > 0) {

  $('.shop-owners-select2').select2({
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to users_controller to get user list
    ajax: {
      url: '/user_search',
      dataType: 'json',
      type: 'GET',
      multiple: true,
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  });

  if ($('.shop-owners-select2').select2('data').length > 0) {
    const shopOwnerData = $('.shop-owners-select2').select2('data')
    $( ".shop-owner-hidden-field-js" ).empty()
    $( ".shop-owner-hidden-field-js" )[0].value = shopOwnerData[0].id
  }

  $('.shop-owners-select2').on("select2:close", function() {
    const shopOwnerData = $('.shop-owners-select2').select2('data')
    $( ".shop-owner-hidden-field-js" ).empty()
    $( ".shop-owner-hidden-field-js" )[0].value = shopOwnerData[0].id
  })

}

// ProdigiItem API

if ($('.js-prodigi-sku-check').length > 0) {
  $( ":checkbox" ).prop('checked', true);
  $('.js-prodigi-sku-check').change(function() {
    var url = window.location.href;
    if (url.indexOf('?') > -1) {
       url += `&sku=${$('.js-prodigi-sku-check')[0].value}`
    } else {
       url += `?sku=${$('.js-prodigi-sku-check')[0].value}`
    }
    window.location.href = url;
  })
}


// Composer/Conductor/Performer Select

function multipleArtistsSelect() {

  const populateHiddenField = (artistList, hiddenField) => {
    const listData = artistList.select2('data')
    const ids = []
    listData.forEach(function (data) {
      ids.push(data.id)
    })
    hiddenField[0].value = ids
  }

  const composerList = $('.composer-list-js')

  if (composerList.length > 0) {

    const composerHiddenField = $(".composer-hidden-field-js")

    composerList.select2({
      width: '100%',
      tags: true,
      minimumInputLength: 1,
      // Uses AJAX request to artists_controller to get artist list
      ajax: {
        url: '/artist_search',
        dataType: 'json',
        type: 'GET',
        delay: 200,
        multiple: true,
        data: function (params) {
          var queryParameters = {
            q: params.term
          }
          return queryParameters;
        },
      }
    })

    // Passes composer ids to hidden form tags if ids are pre-selected
    populateHiddenField(composerList, composerHiddenField)

    // Passes composer ids to hidden form tag if ids change on page
    composerList.change(function() {
      populateHiddenField(composerList, composerHiddenField)
    })

  }

  const performerList = $('.performer-list-js')

  if (performerList.length > 0) {

    const performerHiddenField = $(".performer-hidden-field-js")

    performerList.select2({
      width: '100%',
      tags: true,
      minimumInputLength: 1,
      // Uses AJAX request to artists_controller to get artist list
      ajax: {
        url: '/artist_search',
        dataType: 'json',
        type: 'GET',
        delay: 200,
        multiple: true,
        data: function (params) {
          var queryParameters = {
            q: params.term
          }
          return queryParameters;
        },
      }
    })

    // Passes performer ids to hidden form tags if ids are pre-selected
    populateHiddenField(performerList, performerHiddenField)

    // Passes performer ids to hidden form tag if ids change on page
    performerList.change(function() {
      populateHiddenField(performerList, performerHiddenField)
    })

  }

  const conductorList = $('.conductor-list-js')

  if (conductorList.length > 0) {

    const conductorHiddenField = $(".conductor-hidden-field-js")

    conductorList.select2({
      width: '100%',
      tags: true,
      minimumInputLength: 1,
      // Uses AJAX request to artists_controller to get artist list
      ajax: {
        url: '/artist_search',
        dataType: 'json',
        type: 'GET',
        delay: 200,
        multiple: true,
        data: function (params) {
          var queryParameters = {
            q: params.term
          }
          return queryParameters;
        },
      }
    })

    // Passes conductor ids to hidden form tags if ids are pre-selected
    populateHiddenField(conductorList, conductorHiddenField)

    // Passes conductor ids to hidden form tag if ids change on page
    conductorList.change(function() {
      populateHiddenField(conductorList, conductorHiddenField)
    })

  }
};

if ($('.conductor-list-js').length > 0) {
  multipleArtistsSelect()
}
