const flashMessage = document.querySelectorAll(".c-flash__wrap");

if(flashMessage) {

  flashMessage.forEach(function(message) {
    let closeBtn = message.querySelector(".js-flashClose");

    closeBtn.addEventListener("click", function() {
      // hide each message on close
      message.classList.add("isHidden");

      // When all messages are hidden, also hide the flash message container
      if (flashMessage.length == document.querySelectorAll(".isHidden").length) {
        setTimeout(function() {
          document.querySelector(".c-flash").classList.add('hide');
        }, 250);
      }

    });
  });
}
