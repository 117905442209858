const deviseFields = document.querySelectorAll('.js-password-confirmation');

if ( deviseFields.length > 0 ) {

  deviseFields.forEach(function ( field ) {

    const container = document.createElement('div');
    container.classList.add('c-input__error');
    const message = 'Please enter your password to confirm your change';
    container.innerHTML = message;

    field.addEventListener('change', function () {
      field.after( container );
    });

  });

}
