const config = {
  activeClass: "is-active",
  navigationClass: "js-mobileNav",
  toggleClass: "js-navToggle"
};

const navToggle = document.querySelectorAll(`.${config.toggleClass}`);
const nav = document.querySelectorAll(`.${config.navigationClass}`)[0];
const body = document.getElementsByTagName("body")[0];

const lockBody = () => {
  if (nav.classList.contains(config.activeClass)) {
    body.classList.add("u-scroll-lock");
  } else {
    body.classList.remove("u-scroll-lock");
  }
};

const toggleMobileNavigation = event => {
  event.preventDefault();
  nav && nav.classList.toggle(config.activeClass);
  lockBody();
};

navToggle.forEach(item => {
  item.addEventListener("click", () => {
    toggleMobileNavigation(event);
  });
});
