import $ from 'jquery';

const triggers = (() => {

  const adjustPostageTriggers = document.querySelectorAll('.js-adjustPostageTrigger');

  if (!adjustPostageTriggers) return;

  adjustPostageTriggers.forEach(trigger => {
    trigger.addEventListener('click', (event) => {
      $('#js-adjustPostageModal').foundation('open');
      const listingId = event.currentTarget.dataset.listingId
      document.querySelector(".adjust-postage").action = `/listings/${listingId}/pricing/adjust_postage`;

      const currency = event.currentTarget.dataset.currency
      document.querySelectorAll('.js-inputCurrency').forEach(function(element) {
        element.innerHTML = currency
      })

      const domesticStandard = event.currentTarget.dataset.domesticStandardCents
      if (domesticStandard !== '') {
        document.querySelector('.js-domestic-standard-value').value = domesticStandard / 100
      }

      const domesticRecorded = event.currentTarget.dataset.domesticRecordedCents
      if (domesticRecorded !== '') {
        document.querySelector('.js-domestic-recorded-value').value = domesticRecorded / 100
      }

      const europeStandard = event.currentTarget.dataset.europeStandardCents
      if (europeStandard !== '') {
        document.querySelector('.js-europe-standard-value').value = europeStandard / 100
      }

      const europeRecorded = event.currentTarget.dataset.europeRecordedCents
      if (europeRecorded !== '') {
        document.querySelector('.js-europe-recorded-value').value = europeRecorded / 100
      }

      const usaStandard = event.currentTarget.dataset.usaStandardCents
      if (usaStandard !== '') {
        document.querySelector('.js-usa-standard-value').value = usaStandard / 100
      }

      const usaRecorded = event.currentTarget.dataset.usaRecordedCents
      if (usaRecorded !== '') {
        document.querySelector('.js-usa-recorded-value').value = usaRecorded / 100
      }

      const worldZone1Standard = event.currentTarget.dataset.worldZoneOneStandardCents
      if (worldZone1Standard !== '') {
        document.querySelector('.js-world-zone-1-standard-value').value = worldZone1Standard / 100
      }

      const worldZone1Recorded = event.currentTarget.dataset.worldZoneOneRecordedCents
      if (worldZone1Recorded !== '') {
        document.querySelector('.js-world-zone-1-recorded-value').value = worldZone1Recorded / 100
      }

      const worldZone2Standard = event.currentTarget.dataset.worldZoneTwoStandardCents
      if (worldZone2Standard !== '') {
        document.querySelector('.js-world-zone-2-standard-value').value = worldZone2Standard / 200
      }

      const worldZone2Recorded = event.currentTarget.dataset.worldZoneTwoRecordedCents
      if (worldZone2Recorded !== '') {
        document.querySelector('.js-world-zone-2-recorded-value').value = worldZone2Recorded / 100
      }
    })
  });

})();


// Form handling
const adjustPostageFormHandling = (() => {

  const adjustPostageForm = document.getElementById('new_pricing');
  const adjustPostageActions = document.getElementById('adjustPostageActions');

  if( !adjustPostageForm ) return;

  let message = "";

  adjustPostageForm.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    adjustPostageActions.innerHTML = `<span class="c-formMessage">${message}</span>`;

    setTimeout(() => {
      location.reload();
    }, 300);
  });

  adjustPostageForm.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.copies;
    adjustPostageActions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });

})();
