var getWindowOptions = function() {
  var width = 500;
  var height = 350;
  var left = (window.innerWidth / 2) - (width / 2);
  var top = (window.innerHeight / 2) - (height / 2);

  return [
    'resizable,scrollbars,status',
    'height=' + height,
    'width=' + width,
    'left=' + left,
    'top=' + top,
  ].join();
};
var twitterBtn = document.querySelector('.twitter-share');
var text = encodeURIComponent("Check out this great listing from elvinyl");
var shareUrl = 'https://twitter.com/intent/tweet?url=' + location.href + '&text=' + text;
if (twitterBtn) {
  twitterBtn.href = shareUrl; // 1
  twitterBtn.addEventListener('click', function(e) {
    e.preventDefault();
    var win = window.open(shareUrl, 'ShareOnTwitter', getWindowOptions());
    win.opener = null; // 2
  });
}
