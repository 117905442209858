import $ from 'jquery';

const bidForm = document.getElementById('new_bid');
const bidMessages = document.getElementById('bidMessages');

if ( bidForm ) {
  let message = "";

  bidForm.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg
    if (message.includes('Bid submitted successfully')) {
      bidMessages.innerHTML = `<span class="c-formMessage c-formMessage--success">${message}</span>`;
    } else {
      bidMessages.innerHTML = `<span class="c-formMessage c-formMessage--action">${message}</span>`;
    }
     setTimeout(() => {
      location.reload();
    }, 1000);
  });

  bidForm.addEventListener('ajax:error', event => {
    message = event.detail[0].error?.msg || event.detail[0].errors.price_cents[0];
    bidMessages.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });
}
