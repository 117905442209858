// import $ from "jquery";
// import select2 from 'select2';
// window.select2 = select2();

const genres = document.querySelector('.sub-genre-list-js')
if(genres) {

  let genre_ids = []
  let boxLength = ($('.checkbox-wrap-js :checked').length)
  const boxLimit = 5

  // Creates genre_ids from parent genres that are pre-selected
  $("input[type='checkbox']").each(function() {
    if ( $( this ).is(':checked') ) {
      $('.genre-js').removeClass('hide')
      genre_ids.push($(this).val())
    }
  });

  initializeSelect2()

  // Creates new list of genre_ids if parent selection changes on page
  $("input[type='checkbox']").change(function() {
    $('.genre-js').removeClass('hide')
    genre_ids = []
    $("input[type='checkbox']").each(function() {
      if ( $( this ).is(':checked') ) {
        genre_ids.push($(this).val())
      }
    })
    let subGenreLength = ($('.sub-genre-list-js').select2('data').length)
    if ((boxLength + subGenreLength) >= boxLimit) {
      this.checked = false
    }
    boxLength = ($('.checkbox-wrap-js :checked').length)
    initializeSelect2()
  });

  // Initialises select2 for sub-genres
  function initializeSelect2() {
    $('.sub-genre-list-js').select2({
      width: '100%',
      maximumSelectionLength: boxLimit - boxLength,
      "language": {
        "noResults": function() {
          return "Please select a main genre first";
        }
      },
      // Uses AJAX request to genres_controller to find correct sub_genre list to select from
      ajax: {
        url: '/sub_genres',
        dataType: 'json',
        type: 'GET',
        multiple: true,
        data: function (params) {
          var queryParameters = {
            q: params.term,
            genre_ids: genre_ids
          }
          return queryParameters;
        },
      },
    });
  }

  // Passes sub-genre ids to hidden form tags if ids are pre-selected
  const genreData = $('.sub-genre-list-js').select2('data')
  $( ".sub-genre-hidden-field-js" ).empty()
  genreData.forEach(function (data) {
    $(".sub-genre-hidden-field-js").append(`<input type="hidden" value="${data.id}" name="master[genre_ids][]">`);
  })

  // Passes sub-genre ids to hidden form tag if ids change on page
  $('.sub-genre-list-js').change(function() {
    const genreData = $('.sub-genre-list-js').select2('data')
    $( ".sub-genre-hidden-field-js" ).empty()
    genreData.forEach(function (data) {
      $(".sub-genre-hidden-field-js").append(`<input type="hidden" value="${data.id}" name="master[genre_ids][]">`);
    })
  })

}
