const menuIcon = document.querySelector('.js-myElvinylMenu');
const dashboardWrap = document.querySelector('.js-dashboardWrap');
const close= document.querySelector('.js-menuClose');

const toggleNavigation = () => {
  dashboardWrap.classList.toggle('hide')
  dashboardWrap.classList.toggle('c-dashboard__mobileActive')
}

if (menuIcon) {
  menuIcon.addEventListener("click", () => {
    toggleNavigation()
  });
}
if (close) {

  close.addEventListener("click", () => {
    if (dashboardWrap.classList.contains('c-dashboard__mobileActive')) {
      toggleNavigation()
    }
  });
}
