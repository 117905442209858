import $ from 'jquery';

const offerForm = document.getElementById('new_offer');
const offerActions = document.getElementById('OfferActions');
const submissionHTML = document.querySelector('.js-submission-div')
const successHTML = document.querySelector('.js-success-div')

if (offerForm) {
  $('.js-submit-offer-button').click(function() {
    $('.js-submit-offer-button').off('click')
  })

  let message = "";

  offerForm.addEventListener('ajax:success', event => {
    submissionHTML.remove()
    successHTML.classList.remove('hide')

    setTimeout(() => {
      location.reload();
    }, 1000);
  });

  offerForm.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.offer_price_cents[0];
    offerActions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });
}
