const auctionEnd = document.querySelector('.js-countdown')
const element = document.querySelector('.js-countdown-element')

if (auctionEnd && element) {
  // Update the count down every 1 second
  const countdownDate = new Date(auctionEnd.innerText).getTime()

  const loop = setInterval(function() {
    let timeNow = new Date().getTime()
    // Convert auction end data to integer

    // Work out the difference between auction end and time now
    let distance = countdownDate - timeNow

    // Time calculations for days, hours, minutes and seconds
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element
    let result = ''
    if (hours > 0) {
      result += hours + "h "
    }
    if (minutes > 0) {
      result += minutes + "m "
    }
    if (seconds > 0) {
      result += seconds + "s "
    }
    element.innerHTML = result

    // If the count down is over, write some text
    if (distance < 0) {
      clearInterval(loop);
      element.innerHTML = "Finished";
    }
  }, 1000)
}
