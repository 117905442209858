const questionMark = document.querySelector('.js-question-mark');
const hintBox = document.querySelector('.js-hint-box');

if (questionMark) {

  function is_touch_device() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function(query) {
  return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
  return true;
    }

    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  if (is_touch_device()) {

    const toggleHint = event => {
      hintBox.classList.toggle('c-questionMark__hidden')
    }
    ['click','onClick'].forEach(evt =>
      questionMark.addEventListener(evt, toggleHint, false)
      );
  }
}
