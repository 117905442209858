// import $ from "jquery";
// import select2 from 'select2';
// window.select2 = select2();

const populateHiddenField = (artistList, hiddenField) => {
  const listData = artistList.select2('data')
  const ids = []
  listData.forEach(function (data) {
    ids.push(data.id)
  })
  hiddenField[0].value = ids
}

const composerList = $('.composer-list-js')

if (composerList.length > 0) {

  const composerHiddenField = $(".composer-hidden-field-js")

  composerList.select2({
    tags: true,
    minimumInputLength: 1,
    // Uses AJAX request to artists_controller to get artist list
    ajax: {
      url: '/artist_search',
      dataType: 'json',
      type: 'GET',
      delay: 200,
      multiple: true,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    }
  })

  // Passes composer ids to hidden form tags if ids are pre-selected
  populateHiddenField(composerList, composerHiddenField)

  // Passes composer ids to hidden form tag if ids change on page
  composerList.change(function() {
    populateHiddenField(composerList, composerHiddenField)
  })

}

const performerList = $('.performer-list-js')

if (performerList.length > 0) {

  const performerHiddenField = $(".performer-hidden-field-js")

  performerList.select2({
    tags: true,
    minimumInputLength: 1,
    // Uses AJAX request to artists_controller to get artist list
    ajax: {
      url: '/artist_search',
      dataType: 'json',
      type: 'GET',
      delay: 200,
      multiple: true,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    }
  })

  // Passes performer ids to hidden form tags if ids are pre-selected
  populateHiddenField(performerList, performerHiddenField)

  // Passes performer ids to hidden form tag if ids change on page
  performerList.change(function() {
    populateHiddenField(performerList, performerHiddenField)
  })

}

const conductorList = $('.conductor-list-js')

if (conductorList.length > 0) {

  const conductorHiddenField = $(".conductor-hidden-field-js")

  conductorList.select2({
    tags: true,
    minimumInputLength: 1,
    // Uses AJAX request to artists_controller to get artist list
    ajax: {
      url: '/artist_search',
      dataType: 'json',
      type: 'GET',
      delay: 200,
      multiple: true,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    }
  })

  // Passes conductor ids to hidden form tags if ids are pre-selected
  populateHiddenField(conductorList, conductorHiddenField)

  // Passes conductor ids to hidden form tag if ids change on page
  conductorList.change(function() {
    populateHiddenField(conductorList, conductorHiddenField)
  })

}
