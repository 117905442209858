const forms = document.querySelectorAll('.move-master-js');
if (forms.length > 0) {

  const showMessage = (form, string) => {
    const para = document.createElement("p");
    const node = document.createTextNode(string);
    para.appendChild(node)
    form.appendChild(para)
  }

  forms.forEach(function (form) {
    form.addEventListener('ajax:success', function (event) {
      showMessage(form, "👍")
    })
    form.addEventListener('ajax:error', function (event) {
      showMessage(form, "🚫")
    })
  })
}
