// Mobile actions
const mobileFilterContainer = document.querySelectorAll('.js-mobileFiltersContainer');
const mobileFilterFooter = document.querySelectorAll('.js-mobileFiltersFooter');
const mobileFilterShow = document.querySelectorAll('.js-mobileFiltersShow');
const mobileFilterClose = document.querySelectorAll('.js-mobileFiltersClose');
const largeBreakpoint = 1199;

function toggleMobileFilters(toggle) {

  if(toggle === 'show') {
    mobileFilterContainer.forEach(function (el) {
      el.classList.add('isActive')
    // fade in footer buttons after menu is open
      setTimeout(function () {
        mobileFilterFooter.forEach(function (el) {
          el.classList.add('isActive');
        });
      }, 200);
    });
  } else {
    mobileFilterContainer.forEach(function (el) {
      el.classList.remove('isActive');
    });
    mobileFilterFooter.forEach(function (el) {
      el.classList.remove('isActive');
    });
  }

}

if ( mobileFilterShow ) {
  mobileFilterShow.forEach(function (el) {
    el.addEventListener('click', function () {
      const viewport = window.innerWidth;

      if (viewport < largeBreakpoint) {
        toggleMobileFilters('show');
      }
    });
  });
}

if ( mobileFilterClose ) {
  mobileFilterClose.forEach(function (el) {
    el.addEventListener('click', function () {
      const viewport = window.innerWidth;

      if (viewport < largeBreakpoint) {
        toggleMobileFilters('hide');
      }
    });
  });
}

if ( mobileFilterContainer ) {
  window.addEventListener('resize', function () {
    if (this.innerWidth > largeBreakpoint) {
      toggleMobileFilters('hide');
    }
  });
}

// Filter content blocks
const mobileFilterLinks = document.querySelectorAll('.js-filterToggle');

if ( mobileFilterLinks ) {
  mobileFilterLinks.forEach(function (el) {
    el.addEventListener('click', function (e) {
      e.preventDefault();
      const parent = el.parentNode;
      const content = parent.querySelector('.c-filters__filterContent');

      el.classList.toggle('isActive');
      content.classList.toggle('isActive');
    });
  });
}
