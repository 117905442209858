const tr = document.querySelectorAll('.js-tr')

if (tr.length > 0) {
  const openLink = (item) => {
    window.open(item.getAttribute('data-url'))
  }
  tr.forEach(item => {
    item.addEventListener('click', event => {
      openLink(item)
    })
  })
}
