import $ from 'jquery';

const triggers = (() => {

  const addCopiesTriggers = document.querySelectorAll('.js-addCopiesTrigger');

  if (!addCopiesTriggers) return;

  addCopiesTriggers.forEach(trigger => {
    trigger.addEventListener('click', (event) => {
      $('#js-addCopiesModal').foundation('open');
      const id = event.currentTarget.dataset.formAction
      document.getElementById("new_listing").action = `/listings/${id}/add_copies`;
    })
  });

})();


// Form handling
const addCopiesFormHandling = (() => {

  const addCopiesForm = document.getElementById('new_listing');
  const addCopiesActions = document.getElementById('addCopiesActions');

  if( !addCopiesForm ) return;

  let message = "";

  addCopiesForm.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    addCopiesActions.innerHTML = `<span class="c-formMessage">${message}</span>`;

    setTimeout(() => {
      location.reload();
    }, 300);
  });

  addCopiesForm.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.copies;
    addCopiesActions.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });

})();

