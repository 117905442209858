// import $ from "jquery";
// import select2 from 'select2';
// window.select2 = select2();

// import $ from 'jquery'
// import 'select2'
// window.select2 = select2();

if ($('.artist-select2').length > 0) {

  $('.artist-select2').on('select2:open', () => {
    $(".select2-search__field")[0].focus()
  });

  $('.artist-select2').select2({
    tags: true,
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to artists_controller to get artist list
    ajax: {
      url: '/artist_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  })

  if ($('.artist-select2').select2('data').length > 0) {
    const artistData = $('.artist-select2').select2('data')
    $( ".aritst-hidden-field-js" ).empty()
    $( ".aritst-hidden-field-js" )[0].value = artistData[0].id
    // For method in contribution-prompt.js
    $( ".aritst-hidden-field-js" ).trigger('change');
  }

  $('.artist-select2').on("select2:close", function() {
    const artistData = $('.artist-select2').select2('data')
    $( ".aritst-hidden-field-js" ).empty()
    $( ".aritst-hidden-field-js" )[0].value = artistData[0].id
    // For method in contribution-prompt.js
    $( ".aritst-hidden-field-js" ).trigger('change');
  })

}

if ($('.label-select2').length > 0) {

  $('.label-select2').select2({
    tags: true,
    width: '100%',
    minimumInputLength: 1,
    // Uses AJAX request to labels_controller to get label list
    ajax: {
      url: '/label_search',
      dataType: 'json',
      type: 'GET',
      delay: 250,
      data: function (params) {
        var queryParameters = {
          q: params.term
        }
        return queryParameters;
      },
    },
  });

  if ($('.label-select2').select2('data').length > 0) {
    const labelData = $('.label-select2').select2('data')
    $( ".label-hidden-field-js" ).empty()
    $( ".label-hidden-field-js" )[0].value = labelData[0].id
  }

  $('.label-select2').on("select2:close", function() {
    const labelData = $('.label-select2').select2('data')
    $( ".label-hidden-field-js" ).empty()
    $( ".label-hidden-field-js" )[0].value = labelData[0].id
  })

}
