import $ from 'jquery';

const $mainSlider = $('.js-listingCarousel');
const $modalSlider = $('.js-listingCarouselModal');
const $modalSliderThumbs = $('.js-listingCarouselModalThumbs');
const modals = document.querySelectorAll('.js-modal-open');


if ( $mainSlider && $modalSlider ) {

  $modalSlider.slick({
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false,
    asNavFor: $modalSliderThumbs,
    nextArrow: '<button class="slick-next"></button>',
    prevArrow: '<button class="slick-prev"></button>',
  });

  $modalSliderThumbs.slick({
    accessibility: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    infinite: false,
    adaptiveHeight: false,
    asNavFor: $modalSlider,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8
        }
      },
    ]
  });

  $mainSlider.click(function() {
    var $currentSlide = $mainSlider.find('.slick-current');
    var $currentSlideId = $currentSlide.data('slick-index');

    // Jump modal image to same as main listing slider
    $($modalSlider).slick('slickGoTo', $currentSlideId);
  })

  $('.js-modal-toggle').click(function() {
    // Open reveal modal
    $('#js-listingModal').foundation('open');
  });

  // re-init slick on modal open
  $('[data-reveal]').on('open.zf.reveal', function () {
    $($modalSlider).resize();
  });

  // for archive modals
  modals.forEach(function(element) {
    let idValue = element.id
    let justNumber = idValue.replace(/\D/g,'')
    $(document).on('open.zf.reveal', `#js-listingModal-${justNumber}-modal[data-reveal]`, function() {
      $(`#js-listingCarouselModal-${justNumber}`).slick('refresh');
      $(`#js-archiveThumb-${justNumber}`).slick('refresh');
    });
  })

}

