const searchIcon = document.querySelector('.js-search-icon');
const searchBar = document.querySelector('.js-search-bar');
const logo = document.querySelector('.js-logo');
const input = document.querySelector('.js-search-input');

if (searchIcon) {
  searchIcon.addEventListener("click", () => {
    searchIcon.classList.add("hide");
    logo.classList.add("hide");
    searchBar.classList.remove("show-for-large");
    input.focus();
  })
}
