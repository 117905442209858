import { loadScript } from '@paypal/paypal-js';

const paypalButtonContainers = document.querySelectorAll('.js-paypal-cart-button');

if (paypalButtonContainers.length > 0) {
  Array.prototype.forEach.call(paypalButtonContainers, function(paypalButtonContainer) {
    const currency = paypalButtonContainer.getAttribute('data-currency');
    createPayPalButton(currency);
  });

  function createPayPalButton(currency) {

    const buttonContainer = document.querySelector(`.js-paypal-${currency}-cart-button`)
    const listingIds = document.querySelectorAll(`.js-cart-${currency}-listing-id`)
    const shipping = document.querySelectorAll(`.js-${currency}-shipping`)
    const clientId = document.querySelector('.js-client-id').getAttribute('data-id')
    const merchantIds = buttonContainer.getAttribute('data-merchant-ids')

    shipping.forEach(function (e) {
      e.addEventListener('change', () => {
        buttonContainer.innerHTML = '';
        window.paypalLoadScript(buttonContainer, currency, merchantIds, findValues())
      })
    })

    function shippingValueCheck(ids) {
      const prices = []
      const options = []
      shipping.forEach(function (e) {
        if(e.children[0].checked == true) {
          prices.push(e.children[0].value)
          options.push(e.children[1].innerText)
        }
      })

      const items = []
      ids.forEach(function (id, index) {
        items.push({
          listing_id: id,
          buyer_shipping_cost_cents: prices[index],
          buyer_shipping_option: options[index]
        })
      })
      return items
    }

    function findValues() {
      const ids = Array.from(listingIds).map(function (e) {
        return e.getAttribute('data-value')
      })
      return shippingValueCheck(ids)
    }

    window.paypalLoadScript = function (element, currency, uniqueMerchantIds, items) {
      const options = {
        'client-id': clientId,
        'merchant-id': '*',
        'data-merchant-id': uniqueMerchantIds,
         currency
      };

      if (uniqueMerchantIds.split(',').length === 1) {
        options['merchant-id'] = uniqueMerchantIds
        delete options['data-merchant-id']
      }

      loadScript(options).then(paypal => {

        paypal.Buttons({

          style: {
            layout: 'horizontal',
            size: 'responsive',
            height: 45,
            color: 'gold',
            shape: 'pill',
            label: 'pay',
            tagline: 'false'
          },

          createOrder: function () {
            return fetch(`/payments/cart_payments`, {
              method: 'post',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({payment: items})
            }).then(function (res) {
              return res.json();
            }).then(function (data) {
              if (data.orderID) {
                return data.orderID;
              } else {
                const span = document.createElement("span")
                span.className = "c-formMessage c-formMessage--error"
                span.innerText = "There was an error loading the checkout."
                element.prepend(span);
                throw new Error(data.error['payment']);
              }
            });
          },

          onShippingChange: function (data, actions) {
            return fetch(`/payments/cart_payments/shipping_review`, {
              method: 'put',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                data: data,
                cart: true
              })
            }).then(res => {
              if (!res || res.ok === false) {
                return actions.reject();
              }
              return actions.resolve();
            });
          },

          onApprove: function (data) {
            return fetch(`/payments/cart_payments/execute`, {
              method: 'put',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                orderID: data.orderID
              })
            }).then(function () {
              return window.location.replace('/user_profile/purchases');
            })
          },

          onCancel: function (data) {
            return fetch(`/payments/cart_payments`, {
              method: 'delete',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                orderID: data.orderID
              })
            }).then(function (res) {
              return res.json();
            });
          }

        }).render(element);
      })
    }

    // Inspiration for solution:
    // https://github.com/paypal/paypal-js/compare/reload-currency-scroll-position-demo
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio === 1) {
          buttonContainer.innerHTML = ''
          window.paypalLoadScript(entry.target, entry.target.dataset.currency, entry.target.dataset.merchantIds, findValues())
        }
      });
    });
    observer.observe(buttonContainer);

  }

}
