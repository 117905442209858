import $ from 'jquery';

if ($('.js-shopModalOpen').length > 0) {
  $('.js-shopModalOpen').foundation('open');

  const form = document.querySelector('.new_shop');
  const messages = document.getElementById('messages');

  let message = "";

  form.addEventListener('ajax:success', event => {
    message = event.detail[0].success.msg;
    messages.innerHTML = `<span class="c-formMessage c-formMessage--success">${message}</span>`;

    setTimeout(() => {
      $('.js-shopModalOpen').foundation('close');
    }, 1000);
  });

  form.addEventListener('ajax:error', event => {
    message = event.detail[0].errors.name[0];
    messages.innerHTML = `<span class="c-formMessage c-formMessage--error">${message}</span>`;
  });
}
