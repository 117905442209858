import $ from 'jquery';

if($('.fbsharelink').length > 0) {
  $('.fbsharelink').click( function()
  {
      window.open('https://www.facebook.com/sharer/sharer.php?u='+location.href+'&t='+document.title, '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
  });
}
