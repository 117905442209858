import { loadScript } from '@paypal/paypal-js';

const buttonContainer = document.querySelector('.js-audio-paypal-button')
if (buttonContainer) {

  window.paypalLoadScript = function (container, currency, merchantId, audioListingId, clientId) {
    const options = {
      'client-id': clientId,
      'merchant-id': merchantId,
       currency
    };

    loadScript(options).then(paypal => {

      paypal.Buttons({

        style: {
          layout: 'horizontal',
          size: 'responsive',
          height: 45,
          color: 'gold',
          shape: 'pill',
          label: 'pay',
          tagline: 'false'
        },

        createOrder: function () {
          return fetch(`/payments/file_payments`, {
            method: 'post',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
            payment: {
              id: audioListingId,
              type: 'audio'
            }
          })
          }).then(function (res) {
            return res.json();
          }).then(function (data) {
            if (data.orderID) {
              return data.orderID;
            } else {
              throw new Error(data.error['payment']);
            }
          });
        },

        onApprove: function (data) {
          return fetch(`/payments/file_payments/execute`, {
            method: 'put',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              orderID: data.orderID
            })
          }).then(function () {
            return window.location.replace('/user_profile/purchases');
          })
        },

        onCancel: function (data) {
          return fetch(`/payments/file_payments`, {
            method: 'delete',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              orderID: data.orderID
            })
          }).then(function (res) {
            return res.json();
          });
        }

      }).render(container);
    })
  }
}


const setupPaymentTriggers = (() => {

  const audioPaymentTriggers = document.querySelectorAll('.js-audioPaymentTrigger');

  if (!audioPaymentTriggers) return;

  audioPaymentTriggers.forEach(trigger => {

    trigger.addEventListener('click', (event) => {
      $('#js-audioListingModal').foundation('open');
      const title = event.currentTarget.dataset.formTitle
      document.querySelector('#js-version-title').innerText = title

      const artist = event.currentTarget.dataset.formArtist
      document.querySelector('#js-artist').innerText = artist

      const currency = event.currentTarget.dataset.formCurrency

      const priceCents = event.currentTarget.dataset.formPrice
      let humanizedPrice = priceCents / 100;
      humanizedPrice = humanizedPrice.toLocaleString("en-US", {style:"currency", currency: currency});
      document.querySelector('#js-price').innerText = humanizedPrice

      const buttonContainer = document.querySelector('.js-audio-paypal-button')

      if (buttonContainer) {
        const audioListingId = event.currentTarget.dataset.formAction
        const merchantId = event.currentTarget.dataset.formMerchantId
        const clientId = document.querySelector('.js-client-id').getAttribute('data-id')
        window.paypalLoadScript(buttonContainer, currency, merchantId, audioListingId, clientId)
      }
    })
  });

})();

// Prevent duplicate buttons rendering after modal is closed and another opened
$('#js-audioListingModal').on('closed.zf.reveal', function () {
  const buttonContainer = document.querySelector('.js-audio-paypal-button')
  if (buttonContainer) {
    buttonContainer.innerHTML = '';
  }
});


