import { loadScript } from '@paypal/paypal-js';

const buttonContainer = document.querySelector('.js-listing-file-paypal-button')

if (buttonContainer) {

  window.paypalLoadScript = function (container, currency, merchantId, listingId, clientId) {
    const options = {
      'client-id': clientId,
      'merchant-id': merchantId,
       currency
    };

    loadScript(options).then(paypal => {

      paypal.Buttons({

        style: {
          layout: 'horizontal',
          size: 'responsive',
          height: 45,
          color: 'gold',
          shape: 'pill',
          label: 'pay',
          tagline: 'false'
        },

        createOrder: function () {
          return fetch(`/payments/file_payments`, {
            method: 'post',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
            payment: {
              id: listingId,
              type: 'listing'
            }
          })
          }).then(function (res) {
            return res.json();
          }).then(function (data) {
            if (data.orderID) {
              return data.orderID;
            } else {
              throw new Error(data.error['payment']);
            }
          });
        },

        onApprove: function (data) {
          return fetch(`/payments/file_payments/execute`, {
            method: 'put',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              orderID: data.orderID
            })
          }).then(function () {
            return window.location.replace('/user_profile/purchases');
          })
        },

        onCancel: function (data) {
          return fetch(`/payments/file_payments`, {
            method: 'delete',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              orderID: data.orderID
            })
          }).then(function (res) {
            return res.json();
          });
        }

      }).render(container);
    })
  }
  const clientId = document.querySelector('.js-client-id').getAttribute('data-id')
  const currency = document.querySelector('.js-client-id').getAttribute('data-currency')
  const merchantId = document.querySelector('.js-client-id').getAttribute('data-merchant-id')
  const listingId = document.querySelector('.js-client-id').getAttribute('data-listing-id')

  window.paypalLoadScript(buttonContainer, currency, merchantId, listingId, clientId)
}
